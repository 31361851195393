"use client";
import React from 'react';
import { handleOnChange, handleContactFormSubmit } from '../utils/hooks';
import AnimationPlayer from './animPlayer.component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { isMobile, isBrowser } from 'react-device-detect';

const Contact = ({ data }) => {
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        fullName: "",
        email: "",
        message: ""
    });

    const showAlert = (data) => {
        withReactContent(Swal).fire({ ...data })
    }

    return (
        <section className="text-gray-600 body-font relative">
            <div className="container px-5 py-16 mx-auto flex sm:flex-nowrap flex-wrap">
                {
                    isBrowser &&
                    <div className="lg:w-2/3 md:w-1/2 bg-white rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                        <div className="absolute inset-0 z-0" style={{ marginTop: '-200px' }}>
                            <AnimationPlayer src={"/assets/json/soylent_edu.json"} />
                        </div>
                        <div className="bg-white relative flex flex-wrap py-4 rounded shadow-md w-full">
                            <div className="w-1/2 px-6">
                                <h2 className="title-font font-semibold text-amber-400 tracking-widest text-xs">Email</h2>
                                <a href={`mailto:${data?.contactEmail}`} className="text-sky-500 flex mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                    </svg>
                                    <span>&nbsp;</span>
                                    {data?.contactEmail ?? "info@soylent.in"}
                                </a>
                            </div>
                            <div className="w-1/2 px-6">
                                <h2 className="title-font font-semibold text-amber-400 tracking-widest text-xs">Phone Number</h2>
                                <a href={`tel:${data?.contactPhone}`} className="text-sky-500 leading-relaxed flex mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                    </svg>
                                    <span>&nbsp;</span>
                                    {data?.contactPhone ? `+${data?.contactPhone}` : "+91 7091973540"}
                                </a>
                            </div>
                        </div>
                    </div>
                }
                <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                    <form onSubmit={(e) => handleContactFormSubmit(e, formData, setFormData, setLoading, showAlert)} >
                        <h2 className="text-sky-500 text-lg mb-1 font-medium title-font">Feel free to Contact Us</h2>
                        <p className="leading-relaxed mb-5 text-gray-600">We're here to help you as soon as we can.</p>
                        <div className="relative mb-4">
                            <label htmlFor="fullName" className="leading-7 text-sm text-sky-500">Name</label>
                            <input type="text" id="fullName" name="fullName" placeholder="enter your full name" defaultValue={formData.fullName} value={formData.fullName} onChange={(e) => handleOnChange(e, formData, setFormData)} className="w-full bg-white rounded border border-gray-300 focus:border-sky-500 focus:ring-2 focus:ring-sky-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-sky-500">Email</label>
                            <input type="email" id="email" name="email" placeholder="enter email address" defaultValue={formData.email} value={formData.email} onChange={(e) => handleOnChange(e, formData, setFormData)} className="w-full bg-white rounded border border-gray-300 focus:border-sky-500 focus:ring-2 focus:ring-sky-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="message" className="leading-7 text-sm text-sky-500">Message</label>
                            <textarea id="message" name="message" placeholder="enter your query, message or anything specific!" defaultValue={formData.message} value={formData.message} onChange={(e) => handleOnChange(e, formData, setFormData)} className="w-full bg-white rounded border border-gray-300 focus:border-sky-500 focus:ring-2 focus:ring-sky-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" required></textarea>
                        </div>
                        <button type="submit" className="text-white w-full bg-sky-500 border-0 py-2 px-6 focus:outline-none hover:bg-amber-400 rounded text-lg">Submit</button>
                        <p className="text-xs text-amber-400 mt-3">Feel free to contact us any time. We will get back to you as soon as we can!</p>
                    </form>
                </div>
            </div>
        </section>
    )
};

export default Contact;