"use client";
import React, { useEffect, useRef, useState } from 'react';
import { CountUp } from 'countup.js';
import { fetchCompanySettings } from '../utils/hooks';

const StatsCounter = () => {
    const trainingSessionsRef = useRef(null);
    const hoursOfTrainingRef = useRef(null);
    const professionalsRef = useRef(null);
    const statsSectionRef = useRef(null);
    const [hasStarted, setHasStarted] = useState(false);
    const [settings, setSettings] = React.useState(null);

    React.useEffect(() => {
        fetchCompanySettings(setSettings);
    }, []);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    startCounting();
                    observer.disconnect();
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.3 });

        if (statsSectionRef.current) {
            observer.observe(statsSectionRef.current);
        }

        const startCounting = () => {
            if (hasStarted) return;
            setHasStarted(true);
            const trainingSessionsCountUp = new CountUp(trainingSessionsRef.current, settings?.trainingSession);
            const hoursOfTrainingCountUp = new CountUp(hoursOfTrainingRef.current, settings?.workingHours);
            const professionalsCountUp = new CountUp(professionalsRef.current, settings?.participants);
            if (!trainingSessionsCountUp.error) {
                trainingSessionsCountUp.start();
            } else {
                console.error(trainingSessionsCountUp.error);
            }
            if (!hoursOfTrainingCountUp.error) {
                hoursOfTrainingCountUp.start();
            } else {
                console.error(hoursOfTrainingCountUp.error);
            }
            if (!professionalsCountUp.error) {
                professionalsCountUp.start();
            } else {
                console.error(professionalsCountUp.error);
            }
        };
    }, [hasStarted, settings]);

    return (
        <section className="text-gray-600 body-font" ref={statsSectionRef}>
            {
                settings && (
                    <div className="container px-5 py-12 mx-auto">
                        <div className="flex flex-wrap -m-4 text-center">
                            <div className="p-4 md:w-1/3 sm:w-1/3 w-full">
                                <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-sky-500 w-12 h-12 mb-3 inline-block">
                                        <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                                    </svg>
                                    <h2 className="title-font font-medium text-3xl text-gray-600" ref={professionalsRef}>16 K</h2>
                                    <p className="leading-relaxed text-gray-600">empowering professionals</p>
                                </div>
                            </div>
                            <div className="p-4 md:w-1/3 sm:w-1/3 w-full">
                                <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-sky-500 w-12 h-12 mb-3 inline-block">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <h2 className="title-font font-medium text-3xl text-gray-600" ref={hoursOfTrainingRef}>40 +</h2>
                                    <p className="leading-relaxed text-gray-600">hours of training</p>
                                </div>
                            </div>
                            <div className="p-4 md:w-1/3 sm:w-1/3 w-full">
                                <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-sky-500 w-12 h-12 mb-3 inline-block">
                                        <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                                    </svg>
                                    <h2 className="title-font font-medium text-3xl text-gray-600" ref={trainingSessionsRef}>25</h2>
                                    <p className="leading-relaxed text-gray-600">Training Sessions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </section>
    );
};

export default StatsCounter;
