'use client';
import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import { fetchServices, getServerImageUrl, fetchCategories } from '../utils/hooks';
import RichTextEditorRenderer from './core/BlockRendererClient';
import { isMobile, isBrowser } from 'react-device-detect';


const ServicesPopoverPanel = ({ togglePopover }) => {
    const [services, setServices] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(null);
    const [selectedService, setSelectedService] = React.useState(null);


    React.useEffect(() => {
        fetchServices(setServices);
        fetchCategories(setCategories);
    }, []);

    React.useEffect(() => {
        // if (categories.length > 0) {
            // Set the first category and service as default
            // setSelectedCategory(categories[0].id); 
            setSelectedService(services[4].id)
        // }
    }, [services]);

    const filteredServices = services.filter(service => service?.category?.id === selectedCategory);


    const handleExploreService = (event, serviceId) => {
        event.preventDefault();
        // router.push(`/service/${serviceId}`); TODO:
        console.log("==> handleExploreService ::")
    }

    return (
        <Fragment>
            {
                isMobile &&
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="fixed top-0 left-0 w-full h-full bg-white shadow-lg z-50 overflow-auto mt-14"
                >
                    <div className="container px-4 py-12 mx-auto text-gray-600 body-font">
                        <div className="flex flex-wrap -m-2">
                            <div className="w-full p-2">
                                <div className="bg-gray-100 rounded p-4 h-full">
                                    <button
                                        onClick={togglePopover}
                                        className="px-4 py-2 text-black rounded-md mb-4 float-right"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button> 
                                    <h2 className="title-font font-medium text-lg mb-4">Explore Our Services</h2>
                                    <div className="flex flex-wrap">
                                        <button
                                            className={`rounded-full mx-2 my-2 px-4 ${selectedCategory === null ? 'bg-sky-500 text-white' : 'bg-white text-gray-500'} hover:bg-amber-400 border-2`}
                                            onClick={() => setSelectedCategory(null)}
                                        >
                                            All
                                        </button>
                                        {
                                            categories.length > 0 &&
                                            categories.map((category, index) => {
                                                return (
                                                    <button
                                                        key={`categories-pills-${index}`}
                                                        className={`rounded-full mx-2 my-2 px-4 ${selectedCategory === category.id ? 'bg-sky-500 text-white' : 'bg-white text-gray-500'} hover:bg-amber-400 border-2`}
                                                        onClick={() => setSelectedCategory(category.id)}
                                                    >
                                                        {category.title}
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="flex flex-wrap mt-4">
                                        {
                                            selectedCategory && filteredServices.length > 0
                                            && filteredServices.map(service => (
                                                <div
                                                    key={service.id}
                                                    className="p-2 w-full"
                                                    onClick={(e) => handleExploreService(e, service.id)}
                                                >
                                                    <div className="h-full bg-white p-4 rounded-lg shadow-lg">
                                                        <h3 className="text-sky-500 text-medium font-medium title-font">{service.title}</h3>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {
                                            !selectedCategory && services.length > 0
                                            && services.map(service => (
                                                <div
                                                    key={service.id}
                                                    className="p-2 w-full"
                                                    onClick={(e) => handleExploreService(e, service.id)}
                                                >
                                                    <div className="h-full bg-white p-4 rounded-lg shadow-lg">
                                                        <h3 className="text-sky-500 text-medium font-medium title-font">{service.title}</h3>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }

            {
                isBrowser &&
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="fixed top-0 left-0 w-full h-full bg-white shadow-lg z-50 overflow-auto mt-14 [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#0ea5e9_100%)]"
                >
                    <div className="container px-4 py-12 mx-auto text-gray-600 body-font">
                        {/* <button
                            onClick={togglePopover}
                            className="px-4 py-2 text-black rounded-md mb-4 float-right"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button> */}
                        <div className="flex flex-wrap -m-2">
                            <div className="w-3/4 p-2">
                                <div className="bg-gray-100 rounded p-4 h-full">
                                    <h2 className="title-font font-medium text-lg mb-4">Explore Our Services</h2>
                                    <div className="flex flex-wrap">
                                        <button
                                            className={`rounded-full px-4 ${selectedCategory === null ? 'bg-sky-500 text-white' : 'bg-white text-gray-500'} hover:bg-amber-400 border-2`}
                                            onClick={() => setSelectedCategory(null)}
                                        >
                                            All
                                        </button>
                                        {
                                            categories.length > 0 &&
                                            categories.map((category, index) => {
                                                return (
                                                    <button
                                                        key={`categories-pills-${index}`}
                                                        className={`rounded-full mx-4 px-4 ${selectedCategory === category.id ? 'bg-sky-500 text-white' : 'bg-white text-gray-500'} hover:bg-amber-400 border-2`}
                                                        onClick={() => setSelectedCategory(category.id)}
                                                    >
                                                        {category.title}
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="flex flex-wrap mt-4">
                                        {
                                            selectedCategory && filteredServices.length > 0
                                            && filteredServices.map(service => (
                                                <div
                                                    key={service.id}
                                                    className="p-4 w-full md:w-1/2 lg:w-1/3"
                                                    onClick={() => setSelectedService(service.id)}
                                                >
                                                    <div className="h-full bg-white p-4 rounded-lg shadow-lg">
                                                        <h3 className=" text-sky-500 text-medium font-medium title-font">{service.title}</h3>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {
                                            !selectedCategory && services.length > 0
                                            && services.map(service => (
                                                <div
                                                    key={service.id}
                                                    className="p-4 w-full md:w-1/2 lg:w-1/3"
                                                    onClick={() => setSelectedService(service.id)}
                                                >
                                                    <div className="h-full bg-white p-4 rounded-lg shadow-lg">
                                                        <h3 className="text-sky-500 text-medium font-medium title-font">{service.title}</h3>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/4 p-2">
                                {
                                    selectedService &&
                                    services.filter(item => item.id === selectedService).map(item => {
                                        return (
                                            <>
                                                <div className="max-w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
                                                    <div className="relative">
                                                        <img
                                                            src={item.banner ? getServerImageUrl(item.banner) : "https://via.placeholder.com/1200x400"}
                                                            alt="Card banner"
                                                            className="w-full h-32 object-cover" />
                                                        <div className="absolute bottom-0 w-full bg-black bg-opacity-50 py-2 px-4">
                                                            <h2 className="text-white text-sm font-bold">{item.title}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="p-4">
                                                        <p className="text-gray-700 text-base">
                                                            <RichTextEditorRenderer content={item.description ?? []} />
                                                        </p>
                                                    </div>
                                                    <div className="px-6 pb-4">
                                                        <div className="flex justify-end space-x-4">
                                                            <a href={`/category/${item?.category?.id}`} className="text-sky-500 hover:text-amber-400 cursor-pointer" title='Visit Category Details'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                                </svg>
                                                            </a>
                                                            <button onClick={(e) => handleExploreService(e, item.id)} className='w-full rounded-full bg-sky-500 px-4 py-2 text-white hover:bg-amber-400'>Explore Service</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </Fragment>
    );
};

export default ServicesPopoverPanel;