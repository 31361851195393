'use client';
import React from 'react';
import { fetchCategories } from '../utils/hooks';
// import { useRouter } from 'next/navigation';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = React.useState();
    // const router = useRouter();

    React.useEffect(() => {
        fetchCategories(setCategories);
    }, []);

    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-12 mx-auto">
                <div className="text-center">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 my-12">
                        <motion.h2 
                            className="sm:text-3xl text-2xl font-medium title-font text-center text-sky-400"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1 }}
                        >
                            We deploy skilled workforce,
                            <br /> Impart industry standard knowledge,
                            <br /> Provide unparalleled solutions.
                        </motion.h2>
                    </div>
                    <motion.p
                        className="text-xl leading-relaxed xl:w-3/4 lg:w-3/4 mx-auto"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 2 }}
                    >
                        At <strong>Soylent EdTech</strong>, we believe in empowering the youth with the skills they need to succeed in today's competitive world. Soylent EdTech was founded with a mission to provide skill training to the youth in smaller, rural areas of the country, enabling them to become part of the skilled employability pool.
                    </motion.p>
                </div>
                <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2 py-4">
                    {
                        categories && categories.length > 0
                        && categories.map((item, index) => {
                            return (
                                <div className="p-2 sm:w-1/2 w-full" key={`categories-desktop-list-${index}`}>
                                    <motion.button
                                        className="bg-gray-100 rounded flex p-4 w-full h-full items-center text-sky-500"
                                        onClick={(e) => navigate(`/category/${item.id}`)}
                                        whileHover={{ scale: 1.05, color: "#fbbf24" }}
                                    >
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                            <path d="M22 4L12 14.01l-3-3"></path>
                                        </svg>
                                        <span className="title-font font-medium">{item.title}</span>
                                        <motion.button
                                            className="ml-auto text-sky-500"
                                            onClick={(e) => navigate(`/category/${item.id}`)}
                                            whileHover={{ scale: 1.05, color: "#fbbf24" }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                            </svg>
                                        </motion.button>
                                    </motion.button>
                                </div>
                            )
                        })
                    }
                </div>
                <motion.button
                    onClick={() => navigate('/about-us')}
                    className="flex mx-auto mt-8 text-white bg-sky-500 border-0 py-2 px-8 focus:outline-none hover:bg-amber-400 rounded text-lg"
                    whileHover={{ scale: 1.05, color: "#000000" }}
                >
                    Explore More About Us!
                </motion.button>
            </div>
        </section>
    );
}

export default Categories;
