import React from 'react';
import RootLayout from "../Layout"
import { Outlet } from "react-router-dom";

const Category = () => {
	return (
		<RootLayout>
			<Outlet />
		</RootLayout>
	);
}

export default Category;