import React from "react"
import RootLayout from "../Layout"
import AnimationPlayer from '../components/animPlayer.component';
import Contact from '../components/contact.component';
import { fetchCompanySettings } from '../utils/hooks';
import Categories from '../components/categories.component';
import DataCounter from '../components/dataCounter.component';
import BusinessPartnerSlider from '../components/core/businessPartner.component';
// import { config } from '../utils/config';

const HomePage = () => {
	const [settings, setSettings] = React.useState(null);
	// const [animationData, setAnimationData] = React.useState(null);

	React.useEffect(() => {
		// fetchUpdatedAnimation(setAnimationData);
		fetchCompanySettings(setSettings);
	}, []);

	return (
		<RootLayout settings={settings}>
			<AnimationPlayer src={"/assets/json/edtech.json"} />
			{/* {
				settings?.animationEnableFg && <AnimationPlayer src={`${config.baseUrl}${settings.animation?.File?.url}`} />
			} */}
			<div id='services-category'>
				<Categories />
			</div>
			<DataCounter data={settings} />
			<BusinessPartnerSlider />
			<div id='contact'>
				<Contact data={settings} />
			</div>
		</RootLayout>
	)
}

export default HomePage