import { Fragment } from "react"
import { createBrowserRouter } from "react-router-dom"

import HomePage from "../pages/landing"
import AboutUsPage from "../pages/aboutUs"
import TeamPage from "../pages/team"
import CategoryPage from "../pages/category"
import CategoryDetails from "../pages/categoryDetails"
import ServicePage from "../pages/service"
import ServiceDetails from "../pages/serviceDetails"
import PrivacyPolicyPage from "../pages/privacyPolicy"
import TermsOfServicePage from "../pages/termsOfService"
import MaintenancePage from "../pages/maintenance"

const webappRouter = createBrowserRouter([
    {
        path: "/",
        element: <HomePage />,
    },
    {
        path: "about-us",
        element: <AboutUsPage />,
    },
    {
        path: "team",
        element: <TeamPage />,
    },
    {
        path: "category/",
        element: <CategoryPage />,
        children: [
            {
                path: ":categoryId",
                element: <CategoryDetails />,
            },
        ],
    },
    {
        path: "service/",
        element: <ServicePage />,
        children: [
            {
                path: ":serviceId",
                element: <ServiceDetails />,
            },
        ],
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
    },
    {
        path: "terms-of-service",
        element: <TermsOfServicePage />,
    },
    {
        path: "maintenance",
        element: <MaintenancePage />
    },
    {
        path: "*",
        element: (
            <Fragment>
                <h1>NO Match - 404 Page</h1>
            </Fragment>
        )
    }
]);

export {
    webappRouter
};