import React, { Fragment } from 'react';
import { config } from '../utils/config';
import { isBrowser, isMobile } from 'react-device-detect';
import { AnimatePresence } from 'framer-motion';
import ServicesPopoverPanel from './servicesPopover.component';


const Header = () => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [menuState, setMenuState] = React.useState(false);

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    return (
        <>
            <header className="text-sky-500 bg-white body-font sticky top-0 z-[900] py-2">
                <div className={`container mx-auto flex flex-wrap p-1 md:flex-row ${ isMobile ? 'justify-between' : '' }`}>
                    <a href='/' className="flex title-font font-medium items-center text-amber-400 md:mb-0">
                        <img src={'/assets/logo.png'} height={64} width={64} alt="logo" />
                        <span className="ml-0 text-xl sm:text-medium">{config.setting.siteName}</span>
                    </a>
                    {
                        !isMobile && isBrowser &&
                        <nav className="md:ml-auto flex flex-wrap items-center  text-base justify-center">
                            {
                                config.setting.navigationLinks && config.setting.navigationLinks.length > 0 &&
                                config.setting.navigationLinks?.map((item, index) => {
                                    return (
                                        <Fragment key={`site-nav-${index}`}>
                                            {
                                                item.action === 'href' &&
                                                <a href={item.url} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-sky-500 hover:text-amber-400">{item.label}</a>
                                            }

                                            {
                                                item.action === 'cardPopup' &&
                                                <button
                                                    onClick={togglePopover}
                                                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-sky-500 hover:text-amber-400"
                                                >
                                                    {item.label}
                                                </button>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </nav>
                    }
                    
                    {
                        isMobile && !isBrowser && 
                        <button id="hs-dropdown-default" onClick={() => setMenuState(!menuState)}
                            className="hs-dropdown-toggle mx-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    }
                </div>
            </header>

            <AnimatePresence>
                {
                    isMobile && 
                    <div id='id-nav-menu' className={`hs-dropdown-menu transition-[opacity, margin] duration hs-dropdown-open:opacity-100 opacity-100 min-w-50 bg-white shadow-md rounded p-2 mt-2 after:h-4 absolute z-[999] after:-bottom-4 after:end-0 after:w-full before:h-4 before:-top-4 before:start-0 before:w-full right-2 ${ menuState ? 'block' : 'hidden' }`}
                        aria-labelledby="hs-dropdown-default">
                        {
                            config.setting.navigationLinks && config.setting.navigationLinks.length > 0 &&
                            config.setting.navigationLinks?.map((item, index) => {
                                return (
                                    <Fragment key={`site-nav-${index}`}>
                                        {
                                            item.action === 'href' &&
                                            <a href={item.url} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-sky-500 ">{item.label}</a>
                                        }

                                        {
                                            item.action === 'cardPopup' &&
                                            <button
                                                onClick={togglePopover}
                                                className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-sky-500"
                                            >
                                                {item.label}
                                            </button>
                                        }
                                    </Fragment>
                                )
                            })
                        }   
                    </div>
                }
                {isPopoverOpen && <ServicesPopoverPanel togglePopover={togglePopover} />}
            </AnimatePresence>
        </>
    )
}

export default Header;
