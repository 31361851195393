const config = {
    // baseUrl: 'http://localhost:1337',
    baseUrl: 'https://strapi.soylent.in',
    setting: {
        siteName: 'Soylent EdTech',
        siteDescription: "",
        navigationLinks: [
            {
                id: '23455',
                label: 'Home',
                url: '/',
                enableFg: true,
                action: 'href'
            },
            {
                id: '23456',
                label: 'About',
                url: '/about-us',
                enableFg: true,
                action: 'href'
            },
            {
                id: '23457',
                label: 'Service',
                url: '/service',
                enableFg: true,
                action: 'href'
            },
            {
                id: '23459',
                label: 'Contact',
                url: '/#contact',
                enableFg: true,
                action: 'href'
            },
            // {
            //     id: '23458',
            //     label: 'Portfolio',
            //     url: '/portfolio',
            //     enableFg: true,
            //     action: 'href'
            // },
            {
                id: '23460',
                label: 'Team',
                url: '/team',
                enableFg: true,
                action: 'href'
            }
        ],
        team: {
            members: []
        }
    },
    footerLinks: [
        {
            href: '/about-us',
            title: 'About Us',
            enableFg: true,
        },
        {
            href: '/service',
            title: 'Services',
            enableFg: true,
        },
        {
            href: '/team',
            title: 'Our Team',
            enableFg: true,
        },
        {
            href: '/privacy-policy',
            title: 'Privacy Policy',
            enableFg: true,
        },
        {
            href: 'https://policies.google.com/terms?hl=en',
            title: 'Terms of Service',
            enableFg: true,
        }
    ]
}

export {
    config, 
}