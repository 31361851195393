'use client';
import React from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


const AnimationPlayer = ({ src } ) => {

    return (
        <div className='mt-0'>
            <Player autoplay loop src={src} style={{ height: 'auto', width: '100%' }} >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
        </div>
    )
}

export default AnimationPlayer;