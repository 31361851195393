import React from "react"
import RootLayout from "../Layout"
import Team from '../components/team.component'


const TeamPage = () => {

    return (
        <RootLayout>
            <Team />
        </RootLayout>
    )
}

export default TeamPage