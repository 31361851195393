import React from "react"
import RootLayout from "../Layout"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import StatsCounter from "../components/statsCounter.component"
import BusinessTeam from '../components/core/businessTeam'

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
}


const AboutUsPage = () => {
    const controls = useAnimation()
    const [ref, inView] = useInView({ triggerOnce: true })

    React.useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    return (
        <RootLayout>
            <section className="bg-white-500 lg:py-12 lg:flex lg:justify-center">
                <div className="overflow-hidden bg-white  lg:mx-8 lg:flex lg:max-w-6xl lg:w-full lg:shadow-md lg:rounded-xl">
                    <div className="lg:w-1/2">
                        <div className="h-64 bg-cover lg:h-full" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80')" }}></div>
                    </div>

                    <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2">
                        <h2 className="text-2xl font-semibold text-gray-500  md:text-3xl">
                            Welcome to <span className="text-amber-400">Soylent EdTech!</span>
                        </h2>

                        <p className="mt-4 text-gray-600">
                            At Soylent EdTech!, we are committed to empowering the Indian youth. Our vision is to equip them with the skills they need to become independent,
                            self-reliant professionals. We believe in breaking down technological barriers and building capabilities that enable our youth to contribute effectively to nation-building.
                            By providing the right tools and training, we are dedicated to shaping a brighter future for India.
                        </p>

                        <div className="inline-flex justify-center w-full mt-2 sm:w-auto gap-x-2">
                            <a href="/#services-category" className="inline-flex items-center px-2 py-2 text-mediun text-sky-500 duration-300 hover:text-amber-400 border-b-2 hover:border-amber-400">
                                Our Services Category
                            </a>
                            <a href="/team" className="inline-flex items-center px-2 py-2 text-mediun text-sky-500 duration-300 hover:text-amber-400 border-b-2 hover:border-amber-400">
                                Our Team
                            </a>
                            <a href="/#contact" className="inline-flex items-center px-2 py-2 text-mediun text-sky-500 duration-300 hover:text-amber-400 border-b-2 hover:border-amber-400">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-gray-600 body-font">
                <div className="container px-5 py-8 mx-auto">
                    <div className="text-center mb-4">
                        <p className="text-base sm:text-lg md:text-xl leading-relaxed mx-auto">Here, we believe in empowering the youth with the skills they need to succeed in today's competitive world. Established in 2020, Soylent EdTech was founded with a mission to provide skill training to the youth in smaller, rural areas of the country, enabling them to become part of the skilled employability pool.</p>
                    </div>
                </div>
            </section>

            <section className="text-gray-600 body-font">
                <div className="container px-5 py-8 mx-auto">
                    <div className="text-center mb-4">
                        <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-600 mb-4">What We Do</h1>
                        <p className="text-xl leading-relaxed w-full mx-auto">With a strong team of 18+ experienced trainers, we have trained over 15,000 participants for a total of 40,000 hours since our inception. Our training programs cover a wide range of skills including:</p>
                    </div>
                    <motion.div
                        className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2"
                        initial="hidden"
                        animate={controls}
                        variants={{
                            hidden: { opacity: 1 },
                            visible: {
                                opacity: 1,
                                transition: {
                                    staggerChildren: 0.1
                                }
                            }
                        }}
                        ref={ref}
                    >
                        {[
                            "Personality Development",
                            "Entrepreneurship",
                            "Health and Wellness",
                            "Digital Marketing",
                            "IT Training",
                            "Content Development"
                        ].map((title, index) => (
                            <motion.div
                                className="p-2 sm:w-1/2 w-full"
                                variants={itemVariants}
                                key={index}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="3"
                                        className="text-sky-500 w-6 h-6 flex-shrink-0 mr-4"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span className="title-font font-medium">{title}</span>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </section>

            <section className="text-gray-600 body-font">
                <div className="container px-4 py-8 mx-auto md:px-5">
                    <div className="text-center mb-4">
                        <h1 className="text-xl font-medium text-center title-font text-gray-600 mb-4 sm:text-2xl md:text-3xl">Our Approach</h1>
                        <p className="text-base leading-relaxed mx-auto sm:text-lg md:text-2xl">We understand the importance of reaching learners from all regions. That's why we create and offer our training programs in different vernacular languages, ensuring penetration and inclusion. After completing our training, learners are equipped with the skills they need to start their careers in their desired fields and become self-reliant.</p>
                    </div>
                </div>
            </section>
            <StatsCounter />
            <section className="text-white bg-amber-400 body-font">
                <div className="container px-5 py-12 mx-auto">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-white">
                        We deploy skilled workforce,
                        <br /> Impart industry standard knowledge,
                        <br /> Provide unparalleled solutions.
                    </h1>
                </div>
            </section>
            <BusinessTeam />
        </RootLayout>
    )
}

export default AboutUsPage