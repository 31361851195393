'use client';
import React from 'react';
import { fetchTeamMembers, getServerImageUrl } from '../utils/hooks';
import RichTextEditorRenderer from './core/BlockRendererClient';
import { isBrowser } from 'react-device-detect';
import { motion } from 'framer-motion';

const Team = () => {
    const [teamMember, setTeamMember] = React.useState([]);

    React.useEffect(() => {
        fetchTeamMembers(setTeamMember);
    }, []);

    return (
        <>
            <div className="px-4 py-4 mx-auto md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-0 lg:py-12 body-font">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-500 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                                <defs>
                                    <pattern
                                        id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                        <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                </defs>
                                <rect
                                    fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
                                    width="52"
                                    height="24"
                                />
                            </svg>
                            <span className="relative">Meet</span>
                        </span>{' '}
                        our talented team
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                        At <strong>Soylent EdTech!</strong>, we are committed to <u>empowering</u> the Indian youth.
                        Our vision is to equip them with the skills they need to become independent, self-reliant professionals.
                        Meet our management team
                    </p>
                </div>
                <div className="grid gap-2 mx-auto sm:grid-cols-2 lg:grid-cols-3 lg:max-w-screen-lg ">
                    {
                        isBrowser &&
                        teamMember.filter(item => item.type === "Management").map((item, index) => {
                            return (
                                <motion.div
                                    className='border-gray-200 border p-4 rounded-lg hover:border-sky-500'
                                    key={`team-list-${index}`}
                                    whileHover={{ scale: 1.05 }}  // Add a slight zoom to the entire card for a better effect
                                    transition={{ duration: 0.3 }}
                                >
                                    <motion.img
                                        className="object-cover w-24 h-24 rounded-full shadow"
                                        src={getServerImageUrl(item.profilePicture)}
                                        alt={item.alt}
                                        whileHover={{ scale: 1.3 }}  // Zoom-in effect for the image
                                        transition={{ duration: 0.3 }}
                                    />
                                    <div className="flex flex-col justify-center mt-2">
                                        <p className="text-lg text-sky-500 font-bold">{item.fullName}</p>
                                        <p className="mb-4 text-xs text-amber-400 bold">
                                            {item.type === "Employee" ? "Subject Matter Expert" : item.type}
                                        </p>
                                        <p className="text-sm tracking-wide text-gray-900">
                                            <RichTextEditorRenderer content={item.description ?? []} />
                                        </p>
                                    </div>
                                </motion.div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="flex flex-wrap -m-2 lg:mb-4 lg:mt-12 body-font mx-auto">
                {
                    !isBrowser &&
                    teamMember.filter(item => item.type === "Management").map((item, index) => {
                        return (
                            <div className="p-2 lg:w-1/3 md:w-1/2 w-full" key={`team-list-${index}-mobile`}>
                                <div className="h-full flex flex-col border-gray-200 border p-4 rounded-lg">
                                    <div className="flex items-center">
                                        <img className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={getServerImageUrl(item.profilePicture)} alt={item.alt} />
                                        <div className="flex-grow">
                                            <h2 className="text-gray-900 title-font font-medium">{item.fullName}</h2>
                                            <p className="text-gray-500">{item.type === "Employee" ? "Subject Matter Expert" : item.type}</p>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-sm tracking-wide text-gray-800">
                                            <RichTextEditorRenderer content={item.description ?? []} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className="px-4 py-4 mx-auto md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-0 lg:py-0 body-font">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold leading-none tracking-tight text-gray-500 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                                <defs>
                                    <pattern
                                        id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                        <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                </defs>
                                <rect
                                    fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
                                    width="52"
                                    height="24"
                                />
                            </svg>
                            <span className="relative">Meet</span>
                        </span>{' '}
                        our subject matter experts
                    </h2>
                </div>
                <div className="grid gap-2 mx-auto sm:grid-cols-2 lg:grid-cols-4 lg:max-w-screen-lg">
                    {
                        isBrowser &&
                        teamMember.filter(item => item.type === "Employee").map((item, index) => {
                            return (
                                <motion.div
                                    className='border-gray-200 border p-4 rounded-lg hover:border-sky-500'
                                    key={`team-list-${index}`}
                                    whileHover={{ scale: 1.05 }}  // Add a slight zoom to the entire card for a better effect
                                    transition={{ duration: 0.3 }}
                                >
                                    <motion.img
                                        className="object-cover w-24 h-24 rounded-full shadow"
                                        src={getServerImageUrl(item.profilePicture)}
                                        alt={item.alt}
                                        whileHover={{ scale: 1.3 }}  // Zoom-in effect for the image
                                        transition={{ duration: 0.3 }}
                                    />
                                    <div className="flex flex-col justify-center mt-2">
                                        <p className="text-lg text-sky-500 font-bold">{item.fullName}</p>
                                        <p className="mb-4 text-xs text-amber-400 bold">
                                            {item.type === "Employee" ? "Subject Matter Expert" : item.type}
                                        </p>
                                        <p className="text-sm tracking-wide text-gray-800">
                                            <RichTextEditorRenderer content={item.description ?? []} />
                                        </p>
                                    </div>
                                </motion.div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="flex flex-wrap -m-2 mb-8 body-font mx-auto">
                {
                    !isBrowser &&
                    teamMember.filter(item => item.type === "Employee").map((item, index) => {
                        return (
                            <div className="p-2 lg:w-1/3 md:w-1/2 w-full" key={`team-list-${index}-mobile`}>
                                <div className="h-full flex flex-col border-gray-200 border p-4 rounded-lg">
                                    <div className="flex items-center">
                                        <img className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={getServerImageUrl(item.profilePicture)} alt={item.alt} />
                                        <div className="flex-grow">
                                            <h2 className="text-gray-900 title-font font-medium">{item.fullName}</h2>
                                            <p className="text-gray-500">{item.type === "Employee" ? "Subject Matter Expert" : item.type}</p>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-sm tracking-wide text-gray-800">
                                            <RichTextEditorRenderer content={item.description ?? []} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
}

export default Team;