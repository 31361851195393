'use client';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchBusinessPartners } from "../..//utils/hooks";
import { config } from "../../utils/config";

const CustomSlide = React.memo(({ data }) => {
    return <>
        <img className="mx-auto" key={`partners-slide-${data.id}`} src={`${config.baseUrl}${data.partnerLogo.url}`} height={'150px'} width={'150px'} alt={`${data.fullName}`} />
    </>
});

function BusinessPartnersSlider() {
    const [pageContent, setPageContent] = React.useState([]);
    const settings = {
        dots: false,
        // infinite: true,
        // lazyLoad: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 1500,
        // initialSlide: 0,
        centerMode: true,
        centerPadding: "0px",
        // adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };

    React.useEffect(() => {
        fetchBusinessPartners(setPageContent);
    }, [])

    return (
        <section className="container text-gray-600 body-font mx-auto px-4">
            <div className="max-w-xl mb-4 mx-auto text-center sm:text-center lg:max-w-2xl md:mb-4">
                <h2 className="max-w-lg font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl mx-auto">
                    <span className="relative inline-block">
                        <svg
                            viewBox="0 0 52 24"
                            fill="currentColor"
                            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                        >
                            <defs>
                                <pattern
                                    id="d5589eeb-3fca-4f01-ac3e-983224745704"
                                    x="0"
                                    y="0"
                                    width=".135"
                                    height=".30"
                                >
                                    <circle cx="1" cy="1" r=".7" />
                                </pattern>
                            </defs>
                            <rect
                                fill="url(#d5589eeb-3fca-4f01-ac3e-983224745704)"
                                width="52"
                                height="24"
                            />
                        </svg>
                        <span className="relative">Bridging</span>
                    </span>{' '}
                    success with our valued partners
                </h2>
            </div>
            <div className="container px-5 py-8 mx-auto text-center">
                <div className="slider-container">
                    {
                        pageContent && pageContent.length > 0
                        && <Slider {...settings}>
                            {
                                pageContent.map(item => <CustomSlide key={`partners-slide-${item.id}`} data={item} />)
                            }
                        </Slider>
                    }
                </div>
            </div>
    </section>
    );
}

export default BusinessPartnersSlider;
