'use client';
import React, { Fragment } from 'react';
// import { motion } from 'framer-motion';
// import { isMobile, isBrowser } from 'react-device-detect';
import { fetchTeamMembers, getServerImageUrl, fetchCompanySettings } from '../../utils/hooks';
import RichTextEditorRenderer from './BlockRendererClient';

const BusinessTeam = () => {
    const [teamMember, setTeamMember] = React.useState([]);
	const [settings, setSettings] = React.useState(null);

    React.useEffect(() => {
        fetchTeamMembers(setTeamMember);
		fetchCompanySettings(setSettings);
    }, []);


    return (
        <>
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-16 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        {
                            teamMember && teamMember.length > 0
                            && teamMember.filter((item, index) => item.type === "Business" || item.type === "Business").map((elm, index) => {
                                return (
                                    <div className="xl:w-1/2 md:w-1/2 p-4" key={`id-business-member-${index}`}>
                                        <div className="bg-gray-100 p-6 rounded-lg">
                                            {/* <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" /> */}
                                            <h3 className="text-amber-400 text-xs font-medium title-font">{elm.type}</h3>
                                            <h2 className=" text-lg text-sky-500 font-medium title-font">{elm.fullName}</h2>
                                            <p className='text-xs font-bold'>{elm.remark ?? elm.type}</p>
                                            <div className="flex flex-row px-4 py-2 mb-4">
                                                {/* <a href="#" className="text-green-500 hover:text-amber-400 mx-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                                        <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                                                    </svg>
                                                </a> */}
                                                <a href={`mailto:${settings?.contactEmail ?? '#'}`} className="text-red-500 hover:text-amber-400 mx-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                                        <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                                                        <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                                                    </svg>
                                                </a>
                                                <a href="/team" className="text-sky-500 hover:text-amber-400 mx-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <p className="leading-relaxed text-base">
                                                <RichTextEditorRenderer content={ elm.description ?? [] } />
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default BusinessTeam;