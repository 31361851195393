import React from 'react';
import './style.css';

const CustomLoader = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "auto", height: '100vh' }}>
            <div className="loader"></div>
        </div>
    )
} 

export default CustomLoader;