import axios from 'axios';
import { config } from './config';
import Swal from 'sweetalert2';

const baseApi = config.baseUrl;

const fetchMaintenanceCheck = async (setMaintenanceCheck, setLoading) => {
    try {
        setLoading(true)
        const response = await fetch(`${baseApi}/api/getMaintananceStatus`);
        const data = await response.json()
        setMaintenanceCheck(data.isMaintananceFg)
        setLoading(false)
        return data;
    } catch (error) {
        console.error("fetchMaintenanceCheck error => ", error);
        setLoading(false)
        return { isMaintananceFg : true }
    }
}

const fetchCompanySettings = async (setSettings) => {
    try {
        const res = await axios.get(`${baseApi}/api/getCompanySettings`);
        console.log("fetchCompanySettings => ", res.data);
        setSettings(res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchServices = async (setTeamMember) => {
    try {
        const res = await axios.get(`${baseApi}/api/getServices`);
        setTeamMember(res.data);
        console.log("fetchServices => ", res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchServiceById = async (serviceId, setServiceDetail) => {
    try {
        const res = await axios.get(`${baseApi}/api/services/${serviceId}?populate[Category]=*&populate[Sub_categories]=*&populate[Banner]=*`);
        setServiceDetail(res.data);
        console.log("getServiceById => ", res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchCategories = async (setCategories) => {
    try {
        const res = await axios.get(`${baseApi}/api/getCategories`);
        setCategories(res.data);
        console.log("fetchCategories => ", res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchCategoryById = async (categoryId, setCategoryDetail) => {
    try {
        const res = await axios.get(`${baseApi}/api/categories/${categoryId}?&populate[Banner]=*`);
        setCategoryDetail(res.data);
        console.log("getCategoryById => ", res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchUpdatedAnimation = async (setAnimationData) => {
    try {
        // const res = axios.get(`${baseApi}/api/getUpdatedAnimation`);
        // const data = await res.json();
        // setAnimationData(res.data);
        // return res;
        const response = await fetch(`${baseApi}/api/getUpdatedAnimation`);
        console.log("fetchUpdatedAnimation :: ", "RECEIVED UPDATED JSON DATA", response);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAnimationData(data);
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchTeamMembers = async (setTeamMember) => {
    try {
        const res = await axios.get(`${baseApi}/api/getTeamMember`);
        let tempData = res.data.filter(item => item.enableFg).sort((a, b) => a.order - b.order);
        setTeamMember(tempData);
        return tempData;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchPrivacyPolicy = async (setPageContent) => {
    try {
        const res = await axios.get(`${baseApi}/api/getPrivacyPolicy`);
        console.log("fetchPrivacyPolicy => ", res.data);
        setPageContent(res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchTermsAndConditions = async (setPageContent) => {
    try {
        const res = await axios.get(`${baseApi}/api/getTermsCondition`);
        console.log("fetchTermsAndConditions => ", res.data);
        setPageContent(res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchPortfolioData = async (setPageContent) => {
    try {
        const portfolio = [];
        const tempPortfolio = [];
        const res = await axios.get(`${baseApi}/api/getPortfolioData`);
        console.log("fetchTermsAndConditions => ", res.data);
        for (let i = 0; i < res?.data?.length; i += 3) {
            const className1 = i % 2 === 0 ? "md:p-2 p-1 w-1/2" : "md:p-2 p-1 w-full";
            const className2 = "md:p-2 p-1 w-1/2";
            const className3 = i % 2 === 0 ? "md:p-2 p-1 w-full" : "md:p-2 p-1 w-1/2";

            portfolio.push({ ...res.data[i], className: className1 });

            if (i + 1 < res.data.length) {
                portfolio.push({
                    ...res.data[i + 1],
                    className: className2
                });
            }
            if (i + 2 < res.data.length) {
                portfolio.push({
                    ...res.data[i + 2],
                    className: className3
                });
            }
        }
        for (let i = 0; i < portfolio.length; i += 3) {
            tempPortfolio.push(portfolio.slice(i, i + 3));
        }
        console.log("portfolio :: ", tempPortfolio);
        setPageContent(tempPortfolio);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}

const fetchBusinessPartners = async (setPageContent) => {
    try {
        const res = await axios.get(`${baseApi}/api/getBusinessPartners`);
        console.log("fetchBusinessPartners => ", res.data);
        setPageContent(res.data);
        return res;
    } catch (error) {
        console.error("api error => ", error);
        throw Error(error);
    }
}


const getServerImageUrl = (path) => {
    return `${config.baseUrl}${path.formats?.small?.url || path.formats?.thumbnail?.url}`;
}

const getLargeBannerImageUrl = (path) => {
    let url = '';
    if (path) {
        const { large=false, medium=false, small=false, thumbnail=false } = path?.formats;
        if (large || medium || small || thumbnail) {
            url = `${config.baseUrl}${ large ? large?.url : medium ? medium?.url : small ? small?.url : thumbnail ? thumbnail?.url : "" }`;
        }
    } else {
        url = "https://dummyimage.com/720x600";
    }
    return url
}

const getMediumBannerImageUrl = (path) => {
    let url = '';
    if (path) {
        const { medium=false, small=false, thumbnail=false } = path?.formats;
        if (medium || small || thumbnail) {
            url = `${config.baseUrl}${ medium ? medium?.url : small ? small?.url : thumbnail ? thumbnail?.url : "" }`;
        }
    } else {
        url = "https://dummyimage.com/720x600";
    }
    return url
}

const handleOnChange = (event, formData, setFormData) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
}

const handleContactFormSubmit = async (event, formData, setFormData, setLoading, alertCallback) => {
    event.preventDefault();
    setLoading(true);
    console.log("handleContactFormSubmit =>>> ", formData);
    try {
        let dataRes = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseApi}/api/createContactRequest`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(formData)
        });
        console.log("handleContactFormSubmit =>> ", dataRes);

        if (dataRes.status === 200 && dataRes.statusText === "OK") {
            setFormData({ fullName: "", email: "", subject: "", message: "" });
            setLoading(false);
            alertCallback({
                position: "top-end",
                icon: "success",
                html: "Thanks for contacting us! <br> We will get back to you soon.",
                timer: 4000,
                toast: true,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } else {
            throw new Error("Submission failed");
        }
    } catch (error) {
        setLoading(false);
        alertCallback({
            position: "top-end",
            icon: "error",
            html: "Something went wrong! <br> Please try again!!.",
            timer: 4000,
            toast: true,
            timerProgressBar: true,
            showConfirmButton: false,
        });
    }
};

const alertCallback = (options) => {
    if (Swal) {
        Swal.fire(options);
    }
};


export {
    fetchMaintenanceCheck,
    fetchServices,
    fetchServiceById,
    fetchCategories,
    fetchCategoryById,
    fetchTeamMembers,
    fetchPrivacyPolicy,
    fetchTermsAndConditions,
    fetchPortfolioData,
    fetchBusinessPartners,
    getServerImageUrl,
    getLargeBannerImageUrl,
    getMediumBannerImageUrl,
    fetchCompanySettings,
    handleOnChange,
    handleContactFormSubmit,
    fetchUpdatedAnimation,
    alertCallback
}